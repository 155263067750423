<template>
  <div>
    <el-alert type="warning">
      <template v-slot:title>
        <a href="#" target="_blank"> 操作手册 </a>
      </template>
    </el-alert>
    <el-row :gutter="20" class="mt10">
      <el-col :span="24">
        <num></num>
        <el-row :gutter="20" class="mt10">
          <chart-area></chart-area>
          <chart-spe></chart-spe>
        </el-row>

        <el-row class="mt10">
          <chart-day></chart-day>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import num from './components/Num.vue';
import ChartArea from './components/ChartArea.vue';
import ChartSpe from './components/ChartSpe.vue';
import ChartDay from './components/ChartDay.vue';

export default {
  data() {
    return {
      info: {},
    };
  },
  components:{
    num,
    ChartArea,
    ChartSpe,
    ChartDay,
  }
};
</script>

<style></style>
