<template>
  <el-col :span="12">
    <el-card>
      <div slot="header">
        <span>企业-全国分布</span>
      </div>
      <div id="myChartArea" :style="{ height: '330px' }"></div>
    </el-card>
  </el-col>
</template>

<script>
import * as echarts from "echarts";

import { chartArea } from "@/api/home/admin.js";

export default {
  data() {
    return {
      chartData: [],
      searchName: "",
    };
  },
  methods: {
    drawChart() {
      // 基于准备好的dom，初始化echarts实例
      var myChartArea = echarts.init(document.getElementById("myChartArea"));
      // 绘制图表
      myChartArea.setOption({
        legend: {
          orient: "vertical",
          left: "left",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "企业数量",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["60%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            labelLine: {
              show: false,
            },
            label: {
              show: false,
            },
            data: this.chartData,
          },
        ],
      });

      // myChartArea.on("click", (params) => {
      //   this.$router.push(`/company/search?areaId=${params.data.areaId}`);
      // });
    },
    getData() {
      chartArea().then((res) => {
        res.items.forEach((val) => {
          this.chartData.push({
            value: val.value,
            name: val.name,
            areaId: val.id,
          });
        });

        this.drawChart();
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
