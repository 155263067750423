<template>
  <el-row :gutter="20" class="home-num">
    <el-col :span="6" v-for="item in info.items" :key="item.title">
      <el-card>
        <div slot="header" class="header">
          <span v-text="item.title"></span>
        </div>
        <div class="num">
          <el-link
            type="primary"
            v-text="item.count"
            @click="handleClick(item.title)"
          ></el-link>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { countHome } from "@/api/home/admin.js";

export default {
  data() {
    return {
      info: {},
    };
  },
  methods: {
    handleClick(title) {
      if (title) {
        let paras = "";
        if (title.indexOf("企业") >= 0) {
          paras = "company";
        } else if (title.indexOf("展品") >= 0) {
          paras = "goods";
        }
        this.$router.push(`/${paras}/search`);
      }
    },
    getData() {
      countHome().then((res) => {
        this.info = res;
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.home-num {
  .header {
    font-size: 15px;
  }
  .num {
    text-align: center;
    font-size: 50px;
    line-height: 80px;
    a {
      font-size: 50px;
      line-height: 80px;
    }
  }
}
</style>
