<template>
  <el-col :span="24">
    <el-card>
      <div slot="header" class="clearfix">
        <span>企业、展品走势</span>
      </div>
      <div id="myChart" :style="{ height: '320px' }"></div>
    </el-card>
  </el-col>
</template>

<script>
import * as echarts from "echarts";
import { chartDay } from "@/api/home/admin.js";

export default {
  data() {
    return {
      xAxisData: [],
      seriesData: [],
    };
  },
  methods: {
    drawChart() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          boundaryGap: false,
          data: this.xAxisData,
          splitLine: {
            show: true,
          },
        },
        yAxis: {
          splitNumber: 3,
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#ddd",
            },
          },
          splitLine: {
            show: false,
          },
        },
        grid: {
          top: "3%",
          left: "50",
          right: "4%",
          bottom: "20",
        },
        series: this.seriesData,
      });
    },
    getData() {
      chartDay().then((res) => {
        res.xAxisData.forEach((val) => {
          this.xAxisData.push(val);
        });
        Object.keys(res.seriesData).forEach((key) => {
          this.seriesData.push({
            name: key,
            type: "line",
            data: res.seriesData[key].split(","),
            smooth: true,
          });
        });
        this.drawChart();
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
