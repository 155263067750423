<template>
  <el-col :span="12">
    <el-card>
      <div slot="header">
        <span>全国点击量分布</span>
      </div>
      <div id="myChartSpe" :style="{ height: '330px' }"></div>
    </el-card>
  </el-col>
</template>

<script>
import * as echarts from "echarts";
import { chartSpe } from "@/api/home/admin.js";

export default {
  data() {
    return {
      xAxisData: [],
      seriesData: [],
      xAxisDatas: {},
      searchName: "",
    };
  },
  methods: {
    drawChart() {
      // 基于准备好的dom，初始化echarts实例
      var myChartSpe = echarts.init(document.getElementById("myChartSpe"));
      // 绘制图表
      myChartSpe.setOption({
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.xAxisData,
          boundaryGap: false,
          axisLabel: {
            rotate: 60,
            fontSize: "10",
          },
        },
        grid: {
          top: "3%",
          left: "50",
          right: "4%",
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "bar",
            data: this.seriesData,
            barWidth: "10",
            itemStyle: {
              borderRadius: [30, 30, 0, 0],
            },
          },
        ],
      });

      // myChartSpe.on("click", (params) => {
      //   let areaId = this.xAxisDatas[params.name];
      //   this.$router.push(`/company/search?areaIds=${areaId}`);
      // });
    },
    getData() {
      chartSpe().then((res) => {
        this.xAxisDatas = res.xAxisDatas;
        for (var val in res.xAxisDatas) {
          this.xAxisData.push(val);
        }
        res.seriesData.forEach((val) => {
          this.seriesData.push(val);
        });
        this.drawChart();
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
